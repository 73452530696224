import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { theme, media } from '../theme';
import { Features, NewsRoll, Layout, PreviewContent, Metadata, Image, Content } from '../components';

import Adventure from '../img/logo.inline.svg';
import Tickets from '../img/tickets.inline.svg';

export function IndexPageTemplate({
  heading,
  image,
  mainpitch,
  europemap,
  tickets,
  reviews,
  intro,
}) {

  return (
    <>
      <div className="full-width-image fixed-background" id="hero-image">
        <Image src={image} />
        <div className="container">
          <h1>{heading}</h1>
          <Link to="/" className="with-arrow">Læs mere</Link>
        </div>
      </div>

      <section>

        <div className="container text-left">

          <Adventure />

          <div className="content">
            <h2>{mainpitch.title}</h2>
            <p>{mainpitch.description}</p>
          </div>

        </div>

      </section>

      <section className="europemap text-white">

        <div className="container text-left">

          <div className="content">
            <h2>{europemap.title}</h2>
            <p>{europemap.description}</p>
          </div>
        </div>

      </section>

      <section>

        <div className="container text-left">

          <Tickets />

          <div className="content">
            <h2>{tickets.title}</h2>
            <p>{tickets.description}</p>
          </div>

        </div>

      </section>

      <section className="full-width-image text-white quote">

        <Image src={reviews.image} />

        <div className="container text-center">

          <div className="content">
            <blockquote>
              <p>{reviews.quote}</p>
              <cite>{reviews.cite}</cite>
            </blockquote>
          </div>

        </div>

      </section>

      <section className="blogroll">

        <NewsRoll />

      </section>

      <section className="columns blue-background text-white">
        <div className="container text-center">
          <h2>{intro.heading}</h2>
          <p>{intro.description}</p>

          <Features gridItems={intro.blurbs} />
        </div>
      </section>

    </>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  heading: PropTypes.string,
  mainpitch: PropTypes.object,
  europemap: PropTypes.object,
  tickets: PropTypes.object,
  reviews: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    blurbs: PropTypes.array,
  }),
  isPreviewMode: PropTypes.bool,
};

IndexPageTemplate.defaultProps = {
  isPreviewMode: false,
};

const Hero = styled.div`
  height: 300px;

  @media ${media.landscapeS} {
    height: 400px;
  }

  @media ${media.landscapeM} {
    height: 450px;
  }

  @media ${media.tabletL} {
    height: calc(100vh - 60px - ${theme.headerContainerHeight});
  }

  * {
    height: inherit;
  }
`;

const IndexPage = ({ data }) => {
  const { frontmatter } = data.mdx;

  const { metadata } = frontmatter;
  const title = metadata.title.length > 1 ? metadata.title : frontmatter.heading;
  const description = metadata.description.length > 1 ? metadata.description : '';

  return (
    <Layout>
      <Metadata title={title} description={description} image={frontmatter.image} />
      <IndexPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        europemap={frontmatter.europemap}
        tickets={frontmatter.tickets}
        reviews={frontmatter.reviews}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
      body: PropTypes.object,
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    mdx(frontmatter: { templateKey: { eq: "index-page" } }) {
      body
      frontmatter {
        image {
          childImageSharp {
            fluid(
              maxWidth: 1024,
              quality: 70,
              duotone: {
                highlight: "#cc3232",
                shadow: "#51133d"
              }
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        mainpitch {
          title
          description
        }
        europemap {
          title
          description
        }
        tickets {
          title
          description
        }
        reviews {
          image {
            childImageSharp {
              fluid(
                maxWidth: 2048,
                quality: 60,
                duotone: {
                  highlight: "#cc3232",
                  shadow: "#51133d"
                }
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          quote
          cite
        }
        description
        intro {
          blurbs {
            image {
              publicURL
            }
            text
          }
          heading
          description
        }
        metadata {
          title
          description
        }
      }
    }
  }
`;
